div.appointment-choice {
  padding: 30px 25px;
  border: 0.5px solid #c0c0c0;
  max-width: 350px;
  height: 150px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 700;
}

div.appointment-choice.active,
div.appointment-choice:hover {
  background-color: #f5f7ff;
}

div.appointment-choice span {
  margin-left: 10px;
}

span.strong {
  font-weight: 800;
  padding-right: 40px;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}
